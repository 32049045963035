
@if (orgUserNameFG) {
  <ng-container [formGroup]="orgUserNameFG">
    <!-- Name -->
    <div class="field">
      <app-hidden-input-wrap [FC]="orgUserNameFG.controls.name" [editable]="!orgUserNameFG.controls.name.disabled" [label]="'admin.customer-card-customer.name-input.label' | translate" class="">
        <input type="text" pInputText [formControl]="orgUserNameFG.controls.name">
      </app-hidden-input-wrap>
    </div>

    <!-- Surname -->
    <div class="field">
      <app-hidden-input-wrap [FC]="orgUserNameFG.controls.surname" [editable]="!orgUserNameFG.controls.surname.disabled" [label]="'admin.customer-card-customer.surname-input.label' | translate" class="">
        <input type="text" pInputText [formControl]="orgUserNameFG.controls.surname">
      </app-hidden-input-wrap>
    </div>
  </ng-container>
}

@if (emailFC) {
  <div class="field">
    <app-hidden-input-wrap [FC]="emailFC" [editable]="!emailFC.disabled" [label]="'admin.customer-card-customer.email-input.label' | translate" class="">
      <input type="text" pInputText [formControl]="emailFC">
    </app-hidden-input-wrap>
  </div>
}

<ng-container *ngIf="customDataFG && userData">
  <ng-container [formGroup]="customDataFG">
    <ng-container *ngFor="let d of userData; index as i">
  
      <div *ngIf="customDataFG.controls[d.id] as FC" class="field">
  
        <app-hidden-input-wrap (onClick)="onInputClick(d.dataType, FC)" [FC]="$any(FC)" [editable]="!FC.disabled" [label]="d.dataType !== DataType.TITLES ? d.title : ''" class="">
          <ng-container [ngSwitch]="d.dataType">
        
            <ng-container *ngSwitchCase="DataType.BIRTHDATE">
              <app-date-input [justInput]="true" [FC]="$any(FC)"></app-date-input>
            </ng-container>
            <ng-container *ngSwitchCase="DataType.DATE">
              <app-date-input [justInput]="true" [FC]="$any(FC)"></app-date-input>
            </ng-container>
            <ng-container *ngSwitchCase="DataType.STRING">
              @if (FC.disabled) {
                <span>{{ FC.value }}</span>
              } @else {
                <input type="text" pInputText [formControl]="$any(FC)">
              }
            </ng-container>
            <ng-container *ngSwitchCase="DataType.NUMBER">
              <p-inputNumber appNumberInput [formControl]="$any(FC)" />
            </ng-container>
            <ng-container *ngSwitchCase="DataType.CHECKBOX">
              <p-checkbox [binary]="true" [formControl]="$any(FC)"></p-checkbox>
            </ng-container>
            <ng-container *ngSwitchCase="DataType.CONSENT">
              <div class="flex flex-row column-gap-2">
                <p-checkbox [id]="i" [binary]="true" [formControl]="$any(FC)"></p-checkbox>
                @if (d.collectedAt) {
                  <label [for]="i">{{ 'admin.custom-data-input.billing-info.consent-given-at' | translate }} {{ d.collectedAt | localizedDate:'d.M.yyyy' }}</label>
                }
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="DataType.PHONE_NUMBER">
              <app-phone-number-input [FC]="$any(FC)" />
            </ng-container>
            <ng-container *ngSwitchCase="DataType.TRI_STATE_CHECKBOX">
              <p-selectButton [options]="triStateOptions" [formControl]="$any(FC)" optionLabel="icon" optionValue="value">
                <ng-template let-item pTemplate>
                  <i [class]="item.icon"></i>
                </ng-template>
              </p-selectButton>
            </ng-container>
            <ng-container *ngSwitchCase="DataType.MULTISELECT">
              <app-multiselect-custom-input [userData]="d" [FC]="$any(FC)" />
            </ng-container>
            <ng-container *ngSwitchCase="DataType.BILLING_INFO">
              <div class="flex flex-column">
                <span>{{ FC.value?.name }}</span>
                <span>
                  @if (FC.value?.ico) {
                    <span>{{ 'admin.custom-data-input.billing-info.company-number' | translate }}: {{ FC.value.ico }}</span>
                  }
                  @if (FC.value?.vatNumber) {
                    <span>, {{ 'admin.custom-data-input.billing-info.vat-number' | translate }}: {{ FC.value.vatNumber }}</span>
                  }
                </span>
                <span>
                  @if (FC.value?.address?.street) {
                    <span>{{ FC.value.address.street }}</span>
                  }
                  @if (FC.value?.address?.buildingNumber) {
                    <span> {{ FC.value.address.buildingNumber }}</span>
                  }
                  @if (FC.value?.address?.zip) {
                    <span>, {{ FC.value.address.zip }}</span>
                  }
                  @if (FC.value?.address?.city) {
                    <span>, {{ FC.value.address.city }}</span>
                  }
                </span>
                <span>
                  @if (FC.value?.address?.countryCode) {
                    <span>{{ FC.value.address.countryCode }}, {{ ('country.' + FC.value.address.countryCode.toLowerCase()) | translate }}</span>
                  }
                </span>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="DataType.CONTACT_ADDRESS">
              <div class="flex flex-column">
                <span>{{ FC.value?.name }}</span>
                <span>
                  @if (FC.value?.address?.street) {
                    <span>{{ FC.value.address.street }}</span>
                  }
                  @if (FC.value?.address?.buildingNumber) {
                    <span> {{ FC.value.address.buildingNumber }}</span>
                  }
                  @if (FC.value?.address?.zip) {
                    <span>, {{ FC.value.address.zip }}</span>
                  }
                  @if (FC.value?.address?.city) {
                    <span>, {{ FC.value.address.city }}</span>
                  }
                </span>
                <span>
                  @if (FC.value?.address?.countryCode) {
                    <span>{{ FC.value.address.countryCode }}, {{ ('country.' + FC.value.address.countryCode.toLowerCase()) | translate }}</span>
                  }
                </span>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="DataType.SCROLLING_TEXTAREA">
              <div class="flex flex-row column-gap-2">
                <p-checkbox [id]="i" [binary]="true" [formControl]="$any(FC)"></p-checkbox>
                @if (d.collectedAt) {
                  <label [for]="i">{{ 'admin.custom-data-input.billing-info.consent-given-at' | translate }} {{ d.collectedAt | localizedDate:'d.M.yyyy' }}</label>
                }
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="DataType.TITLES">
              <app-titles-input [titlesFG]="$any(FC)" />
            </ng-container>
            <ng-container *ngSwitchDefault>
              input not implemented!, value: {{ d.value }}
              {{ utilsService.logError('Missing switch case '+d.dataType+' in CustomDataInputsComponent', ErrorSeverity.NOT_IMPLEMENTED) }}
            </ng-container>
        
          </ng-container>
          
        </app-hidden-input-wrap>
        
  
      </div>
    </ng-container>
  </ng-container>
</ng-container>