

<app-modal-header
  (close)="close()">
  <span slot="title">
    {{"admin.address-form-group-modal.title" | translate }}
  </span>
</app-modal-header>

<div *ngIf="FG" class="modal-body">

  <div class="grid pt-2">
    @if (nameFC) {
      <div class="field col-12">
        <span class="p-float-label">
          <input type="text" id="name" pInputText [formControl]="internalNameFC" class="w-full">
          <label for="name">{{"admin.address-form-group-modal.name" | translate }}</label>
        </span>
      </div>
    }

    @if (companyInfoFG) {
      <ng-container [formGroup]="internalCompanyInfoFG">
        <div class="field col-6">
          <span class="p-float-label">
            <input type="text" id="ico" pInputText formControlName="ico" class="w-full">
            <label for="ico">{{"admin.address-form-group-modal.ico" | translate }}</label>
          </span>
        </div>

        <div class="field col-6">
          <span class="p-float-label">
            <input type="text" id="vatNumber" pInputText formControlName="vatNumber" class="w-full">
            <label for="vatNumber">{{"admin.address-form-group-modal.vat-number" | translate }}</label>
          </span>
        </div>
      </ng-container>
    }

    <ng-container [formGroup]="internalFG">
      <div class="field col-8">
        <span class="p-float-label">
          <input type="text" id="street" pInputText formControlName="street" class="w-full">
          <label for="street">{{"admin.address-form-group-modal.street" | translate }}</label>
        </span>
      </div>

      <div class="field col-4">
        <span class="p-float-label">
          <input type="text" id="buildingNumber" pInputText formControlName="buildingNumber" class="w-full">
          <label for="buildingNumber">{{"admin.address-form-group-modal.house-number" | translate }}</label>
        </span>
      </div>

      <div class="field col-12">
        <span class="p-float-label">
          <input type="text" id="city" pInputText formControlName="city" class="w-full">
          <label for="city">{{"admin.address-form-group-modal.city" | translate }}</label>
        </span>
      </div>

      <div class="field col-12">
        <span class="p-float-label">
          <input type="text" id="zip" pInputText formControlName="zip" class="w-full">
          <label for="zip">{{"admin.address-form-group-modal.zip" | translate }}</label>
        </span>
      </div>

      <div class="field col-12">
        <app-country-dropdown-input [countryFC]="internalFG.controls.country" [countryCodeFC]="internalFG.controls.countryCode"></app-country-dropdown-input>
      </div>
    </ng-container>
  </div>

</div>

<div class="modal-footer">

  <button
    (click)="onSubmit()"
    pButton
    label="{{'admin.address-form-group-modal.save' | translate }}">
  </button>

</div>