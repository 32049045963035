import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal } from '../modal';
import { ButtonModule } from 'primeng/button';
import { ModalHeaderComponent } from '../components/modal-header/modal-header.component';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { Subscription, finalize, take } from 'rxjs';
import { UtilsService } from '../../services/utils.service';
import { DividerModule } from 'primeng/divider';
import { CheckboxModule } from 'primeng/checkbox';
import { SelectItem } from 'primeng/api';
import { Operation } from '../../enums/utils/operations.enum';
import { DataType } from '../../enums/collecting-data/data-type.enum';
import { AcceptationRequirementTemplateCode, AcceptationRequirementTemplateCodeName, IAcceptationRequirement } from '../../models/collecting-data/acceptation-requirement.model';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputNumberModule } from 'primeng/inputnumber';
import { IFormFieldSettings } from '../../models/shop-item/form-field-settings.model';
import { ShopItemsService } from '../../services/entities/shop-items/shop-items.service';
import { CollectingFrom } from '../../enums/collecting-data/collecting-from.enum';
import { DataLevel } from '../../enums/collecting-data/data-level.enum';
import { SelectedOrgService } from '../../services/selected-org.service';
import { RoleType } from '../../enums/user/role-types.enum';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { daysString } from '../../components/date-input/date-input.component';
import { EditorModule } from 'primeng/editor';
import { formFieldTitleValidator } from '../form-settings-modal/form-settings-modal.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NumberInputDirective } from '../../directives/number-input.directive';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ICollectedUserData } from '../../models/collecting-data/collected-user-data.model';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BlankInputComponent } from '../../components/blank-input/blank-input.component';
import { RadioButtonModule } from 'primeng/radiobutton';

interface IAcceptationRequirementsFG {
  active: FormControl<boolean>;
  value: FormControl<any>;
  templateCode: FormControl<AcceptationRequirementTemplateCode>;
}

export enum SelectionLimit {
  ONE_ITEM = 'ONE_ITEM',
  MULTIPLE_ITEMS = 'MULTIPLE_ITEMS'
}

interface IFormFieldSettingsForm {
  title: FormControl<string>;
  label: FormControl<string>;
  description: FormControl<string | null>;
  dataType: FormControl<DataType | null>;
  required: FormControl<boolean>;
  acceptationRequirements: FormArray<FormGroup<IAcceptationRequirementsFG>>;
  link?: FormControl<string>;
  multiSelectItems?: FormControl<string>;
  selectionLimit?: FormControl<SelectionLimit>;
  otherOption?: FormControl<boolean>;
  scrollingContent?: FormControl<string | null>;
  isUserData: FormControl<boolean>;
  expirationDays: FormControl<number | null>;
  useAsPrivacyPolicy?: FormControl<boolean>;
  useAsTOS?: FormControl<boolean>;
  useAsBillingInfo?: FormControl<boolean>;
  readonlyContent?: FormControl<string>;
};

export function getAcceptReqFG(templateCode: AcceptationRequirementTemplateCode, requirement?: IAcceptationRequirement) {
  return new FormGroup<IAcceptationRequirementsFG>({
    active: new FormControl<boolean>(requirement ? true : false, { nonNullable: true }),
    templateCode: new FormControl<AcceptationRequirementTemplateCode>(templateCode, { nonNullable: true }),
    value: new FormControl<any>(requirement ? requirement.customParameter!.value : null, { nonNullable: true })
  });
}

@Component({
  selector: 'app-form-field-settings-modal',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, TranslateModule,
    ButtonModule, InputTextModule, DividerModule, CheckboxModule, DropdownModule, InputSwitchModule, InputNumberModule,
    ModalHeaderComponent, InputTextareaModule, EditorModule, NumberInputDirective, SelectButtonModule, NgbTooltipModule,
    BlankInputComponent, RadioButtonModule
  ],
  templateUrl: './form-field-settings-modal.component.html',
  styleUrls: ['./form-field-settings-modal.component.scss']
})
export class FormFieldSettingsModalComponent extends Modal implements OnInit, OnDestroy {
  DataType = DataType;
  AcceptationRequirementTemplateCodeName = AcceptationRequirementTemplateCodeName;
  Operation = Operation;

  @Input() collectedUserData: boolean = false;
  @Input() operation: Operation | undefined;
  @Input() collectingFrom: CollectingFrom | null = null;
  @Input() shopItemId: number | null = null;
  @Input() name: string | null = null;
  @Input() formFieldSettings: IFormFieldSettings | null = null;
  @Output() result = new EventEmitter<{ formFieldSettings?: IFormFieldSettings; collectedUserData?: ICollectedUserData; }>();

  form = new FormGroup<IFormFieldSettingsForm>({
    title: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    label: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    acceptationRequirements: new FormArray<FormGroup<IAcceptationRequirementsFG>>([
      getAcceptReqFG(AcceptationRequirementTemplateCode.MINIMUM_AGE),
      getAcceptReqFG(AcceptationRequirementTemplateCode.MAXIMUM_AGE)
    ]),
    dataType: new FormControl<DataType | null>(null, { nonNullable: true, validators: [Validators.required] }),
    description: new FormControl<string>('', { nonNullable: true }),
    isUserData: new FormControl<boolean>(false, { nonNullable: true }),
    required: new FormControl<boolean>(false, { nonNullable: true }),
    expirationDays: new FormControl<number | null>(null, { nonNullable: true })
  });

  showUserDataDisabledTooltip: boolean = false;

  dataTypesOptions: SelectItem[] = [];
  selectionLimitOptions: SelectItem[] = [];

  saving = false;
  loading = false;

  fetchingCollectedDataShortUid: boolean = false;
  collectedUserDataShortUid: string | null = null;

  subs: Subscription[] = [];

  constructor(
    private utilsService: UtilsService,
    private shopItemService: ShopItemsService,
    private selectedOrgService: SelectedOrgService,
    private translate: TranslateService
  ) {
    super();

    this.subs.push(
      this.translate.onLangChange.subscribe(() => { this.createOptions(); })
    );
  }

  ngOnInit(): void {
    if (this.formFieldSettings) {
      if (this.formFieldSettings.collectedUserDataId) {
        this.form.controls.dataType.disable();
        this.form.controls.dataType.updateValueAndValidity();
      }
      this.handleDataTypeSpecificFields(this.formFieldSettings.dataType);
      if (!this.collectedUserData && this.formFieldSettings.collectedUserDataId) {
        this.form.controls.expirationDays.disable();
        this.form.controls.link?.disable();
        this.form.controls.multiSelectItems?.disable();
        this.form.controls.otherOption?.disable();
        this.form.controls.scrollingContent?.disable();
        this.form.controls.selectionLimit?.disable();
        this.form.controls.title.disable();
        this.form.updateValueAndValidity();
        this.showUserDataDisabledTooltip = true;
      }
      this.patchForm();
    } else {
      this.form.controls.title.setValue(this.name!);
      this.form.controls.label.setValue(this.name!);
    }

    if (this.shopItemId) {
      this.loading = true;
      this.shopItemService.getUnusedCollectedUserData(this.shopItemId).pipe(take(1)).subscribe((res) => {
        this.loading = false;
        this.form.controls.title.addValidators(formFieldTitleValidator.bind(this, res.map((x) => x.data.title)));
        this.form.updateValueAndValidity();
      });
    }

    if (this.formFieldSettings?.collectedUserDataId) {
      this.fetchingCollectedDataShortUid = true;
      this.shopItemService.getCollectedUserDataShortUid(this.formFieldSettings.collectedUserDataId).pipe(take(1)).subscribe({
        next: (res) => {
          this.collectedUserDataShortUid = res;
          this.fetchingCollectedDataShortUid = false;
        },
        error: () => {
          this.fetchingCollectedDataShortUid = false;
        }
      });
    }

    this.createOptions();

    this.watchForm();
  }

  watchForm() {
    this.acceptationRequirementsFA.controls.forEach((control) => {
      this.subs.push(
        control.controls.active.valueChanges.subscribe((active) => {
          if (active) {
            control.controls.value.addValidators(Validators.required);
            control.controls.value.updateValueAndValidity();
          } else {
            control.controls.value.removeValidators(Validators.required);
            control.controls.value.updateValueAndValidity();
          }
        })
      );
    });
    this.subs.push(this.dataTypeFC.valueChanges.subscribe((dataType) => {
      if (dataType) this.handleDataTypeSpecificFields(dataType);
    }));
    this.subs.push(this.useAsPrivacyPolicyFC?.valueChanges.subscribe((useAsPrivacyPolicy) => {
      if (useAsPrivacyPolicy) {
        this.form.controls.required.setValue(true);
        this.form.controls.required.disable();
      } else if (!this.form.controls.useAsTOS?.value) {
        this.form.controls.required.enable();
      }
    }) || Subscription.EMPTY);
    this.subs.push(this.useAsTOSFC?.valueChanges.subscribe((useAsTOS) => {
      if (useAsTOS) {
        this.form.controls.required.setValue(true);
        this.form.controls.required.disable();
      } else if (!this.form.controls.useAsPrivacyPolicy?.value) {
        this.form.controls.required.enable();
      }
    }) || Subscription.EMPTY);
  }

  patchForm() {
    if (!this.formFieldSettings) return;
    const required = this.formFieldSettings.acceptationRequirements.some((x) => x.templateCode === AcceptationRequirementTemplateCode.REQUIRED);
    const useAs = this.formFieldSettings.useAsPrivacyPolicy || this.formFieldSettings.useAsTOS;
    this.form.patchValue({
      dataType: this.formFieldSettings.dataType,
      description: this.formFieldSettings.description,
      isUserData: !!this.formFieldSettings.collectedUserDataId,
      required: required,
      title: this.formFieldSettings.title,
      label: this.formFieldSettings.label,
      acceptationRequirements: [AcceptationRequirementTemplateCode.MINIMUM_AGE, AcceptationRequirementTemplateCode.MAXIMUM_AGE].map((templateCode) => {
        const requirement = this.formFieldSettings!.acceptationRequirements.find((req) => req.templateCode === templateCode);
        const res = {
          active: false,
          templateCode: templateCode,
          value: null
        };
        if (requirement) {
          res.active = true;
          res.value = requirement.customParameter!.value;
        }
        return res;
      }),
      link: this.formFieldSettings.link,
      expirationDays: this.formFieldSettings.expirationDays,
      multiSelectItems: this.formFieldSettings?.options?.join('\n') || '',
      scrollingContent: this.formFieldSettings.scrollingContent || null,
      useAsPrivacyPolicy: this.formFieldSettings.useAsPrivacyPolicy,
      useAsTOS: this.formFieldSettings.useAsTOS,
      otherOption: this.formFieldSettings.otherOption,
      selectionLimit: this.formFieldSettings.selectionLimit,
      useAsBillingInfo: this.formFieldSettings.useAsBillingInfo,
      readonlyContent: this.formFieldSettings.readonlyContent
    });
    // Disable required switch if field is used as TOS/PrivacyPolicy
    if (useAs) {
      this.form.controls.required.disable();
    }
    // Disable option if it was already created as collectedUserData
    if (this.formFieldSettings.collectedUserDataId) {
      this.form.controls.isUserData.disable();
    }
  }

  handleDataTypeSpecificFields(dataType: DataType) {
    if (dataType === DataType.CHECKBOX || dataType === DataType.CONSENT) {
      if (!this.form.contains('link')) {
        this.form.addControl('link', new FormControl<string>(this.formFieldSettings?.link || '', { nonNullable: true }), { emitEvent: true });
      }
    } else {
      if (this.form.contains('link')) {
        this.form.removeControl('link', { emitEvent: true });
      }
    }

    if (dataType === DataType.CONSENT || dataType === DataType.TRI_STATE_CHECKBOX || dataType === DataType.SCROLLING_TEXTAREA) {
      this.form.addControl('useAsPrivacyPolicy', new FormControl<boolean>(false, { nonNullable: true }));
      this.form.addControl('useAsTOS', new FormControl<boolean>(false, { nonNullable: true }));
    } else {
      if (this.form.contains('useAsPrivacyPolicy')) {
        this.form.removeControl('useAsPrivacyPolicy', { emitEvent: true });
      }
      if (this.form.contains('useAsTOS')) {
        this.form.removeControl('useAsTOS', { emitEvent: true });
      }
      this.form.controls.required.enable();
    }

    if (dataType === DataType.MULTISELECT) {
      this.form.addControl('multiSelectItems', new FormControl<string>(this.formFieldSettings?.options?.join('\n') || '', { nonNullable: true, validators: [Validators.required] }), { emitEvent: true });
      this.form.addControl('selectionLimit', new FormControl<SelectionLimit>(this.formFieldSettings?.selectionLimit ?? SelectionLimit.MULTIPLE_ITEMS, { validators: [Validators.required], nonNullable: true }));
      this.form.addControl('otherOption', new FormControl<boolean>(this.formFieldSettings?.otherOption ?? false, { nonNullable: true }));
    } else {
      if (this.form.contains('multiSelectItems')) {
        this.form.removeControl('multiSelectItems', { emitEvent: true });
      }
      if (this.form.contains('selectionLimit')) {
        this.form.removeControl('selectionLimit', { emitEvent: true });
      }
      if (this.form.contains('otherOption')) {
        this.form.removeControl('otherOption', { emitEvent: true });
      }
    }

    if (dataType === DataType.SCROLLING_TEXTAREA) {
      if (!this.form.contains('scrollingContent')) {
        this.form.addControl('scrollingContent', new FormControl<string | null>(this.formFieldSettings?.scrollingContent || null, { nonNullable: true, validators: [Validators.required] }), { emitEvent: true });
      }
    } else {
      if (this.form.contains('scrollingContent')) {
        this.form.removeControl('scrollingContent', { emitEvent: true });
      }
    }

    if (dataType === DataType.BILLING_INFO) {
      this.form.addControl('useAsBillingInfo', new FormControl<boolean>(this.formFieldSettings?.useAsPrivacyPolicy ?? true, { nonNullable: true }));
    } else {
      if (this.form.contains('useAsBillingInfo')) {
        this.form.removeControl('useAsBillingInfo', { emitEvent: true });
      }
    }

    if (dataType === DataType.READONLY_TEXT) {
      this.form.addControl('readonlyContent', new FormControl<string>(this.formFieldSettings?.readonlyContent || '', { nonNullable: true, validators: [Validators.required] }));
    } else {
      if (this.form.contains('readonlyContent')) {
        this.form.removeControl('readonlyContent', { emitEvent: true });
      }
    }

    if (dataType === DataType.TITLES || dataType === DataType.MAX_COUNT) {
      this.form.controls.title.removeValidators(Validators.required);
      this.form.controls.title.updateValueAndValidity();
    } else {
      this.form.controls.title.addValidators(Validators.required);
      this.form.controls.title.updateValueAndValidity();
    }

    if (dataType === DataType.MAX_COUNT) {
      this.form.controls.dataType.disable({ emitEvent: false });
      this.form.controls.dataType.updateValueAndValidity({ emitEvent: false });
    } else {
      this.form.controls.dataType.enable({ emitEvent: false });
      this.form.controls.dataType.updateValueAndValidity({ emitEvent: false });
    }
  }

  onSubmit() {
    const formVals = this.form.getRawValue();

    this.utilsService.markFormGroupDirty(this.form);
    if (this.form.invalid) {
      console.error('invalid');
      return;
    }

    if (!this.shopItemId) {
      console.error('missing shopitemId');
      return;
    }

    this.saving = true;

    const acceptationRequirements: IAcceptationRequirement[] = formVals.acceptationRequirements.filter((x) => x.active).map((x) => ({
      description: 'desc', // TODO: remove
      templateCode: x.templateCode,
      customParameter: { value: x.value }
    }));
    if (formVals.required) {
      acceptationRequirements.push({
        description: 'required',
        templateCode: AcceptationRequirementTemplateCode.REQUIRED
      });
    }

    if (this.operation === Operation.CREATE) {
      this.shopItemService.upsertFormSettings({
        shopItemId: this.shopItemId!, formFieldSettings: {
          disabled: false,
          acceptationRequirements: acceptationRequirements,
          active: true,
          collectingFrom: this.collectingFrom!,
          dataLevel: formVals.isUserData ? DataLevel.USER : DataLevel.RESERVATION,
          dataType: formVals.dataType!,
          description: formVals.description!,
          id: '-1',
          immutable: true, // TODO: ???
          organizationId: this.selectedOrgService.getCurrentValue()!,
          title: formVals.title,
          label: formVals.label,
          userRoleType: RoleType.CUSTOMER,
          collectedUserDataId: undefined,
          link: formVals.link,
          expirationDays: formVals.expirationDays,
          options: formVals.multiSelectItems ? formVals.multiSelectItems.split('\n').filter((x) => x) : [],
          scrollingContent: formVals.scrollingContent,
          useAsPrivacyPolicy: formVals.useAsPrivacyPolicy,
          useAsTOS: formVals.useAsTOS,
          useAsBillingInfo: formVals.useAsBillingInfo,
          readonlyContent: formVals.readonlyContent,
          selectionLimit: formVals.selectionLimit,
          otherOption: formVals.otherOption
        }
      }).pipe(
        take(1),
        finalize(() => this.saving = false)
      ).subscribe({
        next: (res) => {
          this.result.emit({ formFieldSettings: res });
          this.close();
        }
      });
    }
    if (this.operation === Operation.UPDATE) {
      if (this.collectedUserData) {
        if (!this.formFieldSettings?.collectedUserDataId) {
          console.error('missing collectedUserDataId!');
          return;
        }
        this.shopItemService.updateCollectedUserDataField({
          dataType: formVals.dataType!,
          expirationDays: formVals.expirationDays,
          id: this.formFieldSettings.collectedUserDataId,
          immutable: true, // TODO: ?
          oldCollectedUserDataId: this.formFieldSettings.collectedUserDataId,
          organizationId: this.selectedOrgService.getCurrentValue()!,
          params: {
            disabled: false,
            acceptationRequirements: acceptationRequirements,
            active: true,
            collectingFrom: this.collectingFrom!,
            dataLevel: formVals.isUserData ? DataLevel.USER : DataLevel.RESERVATION,
            description: formVals.description!,
            link: formVals.link,
            options: formVals.multiSelectItems ? formVals.multiSelectItems.split('\n').filter((x) => x) : [],
            scrollingContent: formVals.scrollingContent,
            useAsPrivacyPolicy: formVals.useAsPrivacyPolicy,
            useAsTOS: formVals.useAsTOS,
            useAsBillingInfo: formVals.useAsBillingInfo,
            readonlyContent: formVals.readonlyContent,
            selectionLimit: formVals.selectionLimit,
            otherOption: formVals.otherOption
          },
          title: formVals.title,
          userRoleType: RoleType.CUSTOMER
        }).pipe(
          take(1),
          finalize(() => this.saving = false)
        ).subscribe({
          next: (res) => {
            this.result.emit({ collectedUserData: res });
            this.close();
          }
        });
      } else {
        this.shopItemService.upsertFormSettings({
          shopItemId: this.shopItemId!, formFieldSettings: {
            disabled: false,
            acceptationRequirements: acceptationRequirements,
            active: true,
            collectingFrom: this.collectingFrom!,
            dataLevel: formVals.isUserData ? DataLevel.USER : DataLevel.RESERVATION,
            dataType: formVals.dataType!,
            description: formVals.description!,
            id: this.formFieldSettings!.id,
            immutable: this.formFieldSettings!.immutable, // TODO: ???
            organizationId: this.formFieldSettings!.organizationId,
            title: formVals.title,
            label: formVals.label,
            userRoleType: RoleType.CUSTOMER,
            collectedUserDataId: formVals.isUserData ? (this.formFieldSettings?.collectedUserDataId ? this.formFieldSettings.collectedUserDataId : -1) : undefined,
            link: formVals.link,
            expirationDays: formVals.expirationDays,
            options: formVals.multiSelectItems ? formVals.multiSelectItems.split('\n').filter((x) => x) : [],
            scrollingContent: formVals.scrollingContent,
            useAsPrivacyPolicy: formVals.useAsPrivacyPolicy,
            useAsTOS: formVals.useAsTOS,
            useAsBillingInfo: formVals.useAsBillingInfo,
            readonlyContent: formVals.readonlyContent,
            selectionLimit: formVals.selectionLimit,
            otherOption: formVals.otherOption
          }
        }).pipe(
          take(1),
          finalize(() => this.saving = false)
        ).subscribe({
          next: (res) => {
            this.result.emit({ formFieldSettings: res });
            this.close();
          }
        });
      }
    }
  }

  onSelect(e: any) {

  }

  onUserDataSelect(value: boolean) {
    this.form.controls.isUserData.setValue(value);
  }

  private createOptions() {
    this.createDataTypeOptions();
    this.createSelectionLimitOptions();
  }
  private createDataTypeOptions() {
    const possibleDataTypes = [
      DataType.DATE,
      DataType.BIRTHDATE,
      DataType.STRING,
      DataType.NUMBER,
      DataType.PHONE_NUMBER,
      DataType.CONSENT,
      DataType.CHECKBOX,
      DataType.TRI_STATE_CHECKBOX,
      DataType.MULTISELECT,
      DataType.BILLING_INFO,
      DataType.SCROLLING_TEXTAREA,
      DataType.CONTACT_ADDRESS,
      DataType.READONLY_TEXT,
      DataType.TITLES
    ];
    if (this.formFieldSettings?.dataType === DataType.MAX_COUNT) {
      possibleDataTypes.push(DataType.MAX_COUNT);
    }

    const options = possibleDataTypes.map(x => {
      return {
        value: x,
        label: this.translate.instant(`DataType.${x}`)
      };
    });
    this.dataTypesOptions = options;
  }
  private createSelectionLimitOptions() {
    const options = Object.values(SelectionLimit).map(x => {
      return {
        value: x,
        label: this.translate.instant(`SelectionLimit.${x}`)
      };
    });
    this.selectionLimitOptions = options;
  }

  get useAsTOSFC() {
    return this.form.controls.useAsTOS;
  }
  get useAsPrivacyPolicyFC() {
    return this.form.controls.useAsPrivacyPolicy;
  }
  get useAsBillingInfoFC() {
    return this.form.controls.useAsBillingInfo;
  }

  get requiredFC() {
    return this.form.controls.required;
  }

  get dataTypeFC() {
    return this.form.controls.dataType;
  }

  get selectionLimitFC() {
    return this.form.controls.selectionLimit;
  }
  get otherOptionFC() {
    return this.form.controls.otherOption;
  }

  get acceptationRequirementsFA() {
    return this.form.controls.acceptationRequirements;
  }

  get daysString() {
    const numberOfDays = this.form.controls.expirationDays.value;
    return daysString(numberOfDays);
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
