import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { InputMaskModule } from 'primeng/inputmask';
import { SelectButtonModule } from 'primeng/selectbutton';
import { HiddenInputWrapComponent } from 'src/app/shared/components/hidden-input-wrap/hidden-input-wrap.component';
import { DateInputComponent } from 'src/app/shared/components/date-input/date-input.component';
import { DataType } from 'src/app/shared/enums/collecting-data/data-type.enum';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { GetCustomDataResultType } from 'src/app/shared/services/entities/users/customer/customers.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { InputNumberModule } from 'primeng/inputnumber';
import { NumberInputDirective } from 'src/app/shared/directives/number-input.directive';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
import { ModalService } from 'src/app/shared/services/modal.service';
import { AddressFormGroupModalComponent, getAddressFG, getCompanyInfoFG, IContactAddress } from 'src/app/shared/modals/address-form-group-modal/address-form-group-modal.component';
import { ICompanyBillingInfo } from 'src/app/shared/models/organization/company-billing-info.model';
import { take } from 'rxjs';
import { PhoneNumberInputComponent } from 'src/app/shared/components/phone-number-input/phone-number-input.component';
import { InputTextModule } from 'primeng/inputtext';
import { MultiselectCustomInputComponent } from './multiselect-custom-input/multiselect-custom-input.component';
import { ErrorSeverity } from 'src/app/shared/services/error.service';
import { TitlesInputComponent } from './titles-input/titles-input.component';

export type ICustomDataFG = FormGroup<{
  [id: string]: FormControl<any> | FormGroup<any>;
}>;

export type IOrgUserNameFG = FormGroup<{
  name: FormControl<string>;
  surname: FormControl<string>;
}>;

@Component({
  selector: 'app-custom-data-inputs',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, PhoneNumberInputComponent,
    SelectButtonModule, InputMaskModule, CheckboxModule, LocalizedDatePipe, InputTextModule,
    DateInputComponent, HiddenInputWrapComponent, InputNumberModule, NumberInputDirective, TranslateModule,
    MultiselectCustomInputComponent, TitlesInputComponent
  ],
  templateUrl: './custom-data-inputs.component.html',
  styleUrls: ['./custom-data-inputs.component.scss']
})
export class CustomDataInputsComponent {
  DataType = DataType;
  ErrorSeverity = ErrorSeverity;

  @Input() customDataFG: ICustomDataFG | undefined;
  @Input() orgUserNameFG: IOrgUserNameFG | undefined;
  @Input() emailFC: FormControl<string> | undefined;
  @Input() userData: GetCustomDataResultType[] | undefined;

  constructor(
    public utilsService: UtilsService,
    private modalService: ModalService
  ) {}

  triStateOptions: SelectItem[] = [
    {
      icon: 'pi pi-check text-xs',
      value: true
    },
    {
      icon: 'pi pi-times text-xs',
      value: false
    }
  ];

  getObjectValues(obj: any) {
    const out: any = [];
    if (!obj) {
      return out;
    }
    const vals = Object.values(obj);
    for (let val of vals) {
      if (val) {
        if (typeof val === 'object') {
          vals.push(...this.getObjectValues(val));
        } else {
          out.push(val);
        }
      }
    }
    return out
  }

  onInputClick(dataType: DataType, FC: FormControl<any> | FormGroup<any>) {
    switch (dataType) {
      case DataType.BILLING_INFO:
        this.onEditBillingInfo(FC as FormControl<any>);
        break;
      case DataType.CONTACT_ADDRESS:
        this.onEditContactAddress(FC as FormControl<any>);
        break;
      default:
        break;
    }
  }

  private onEditBillingInfo(control: FormControl<any>) {
    const required = control.hasValidator(Validators.required);
    const billingInfo: ICompanyBillingInfo | undefined = control.getRawValue();
    const addressFG = getAddressFG({
      address: billingInfo?.address,
      required
    });
    const nameFC = new FormControl<string>(billingInfo?.name || '', { nonNullable: true, validators: required ? [Validators.required] : undefined });
    const companyInfoFG = getCompanyInfoFG({
      ico: billingInfo?.ico,
      required,
      vatNumber: billingInfo?.vatNumber
    });
    this.modalService.openAddressFormGroupModal(AddressFormGroupModalComponent, addressFG, nameFC, companyInfoFG).pipe(take(1)).subscribe(() => {
      const newBillingInfo: ICompanyBillingInfo = {
        address: addressFG.getRawValue(),
        ico: companyInfoFG.controls.ico.getRawValue(),
        vatNumber: companyInfoFG.controls.vatNumber.getRawValue(),
        name: nameFC.getRawValue()
      };
      control.setValue(newBillingInfo);
      control.markAsDirty();
    });

  }

  private onEditContactAddress(control: FormControl<any>) {
    const required = control.hasValidator(Validators.required);
    const contactAddress: IContactAddress = control.getRawValue();
    const addressFG = getAddressFG({
      address: contactAddress.address,
      required
    });
    const nameFC = new FormControl<string>(contactAddress?.name || '', { nonNullable: true, validators: required ? [Validators.required] : undefined });
    this.modalService.openAddressFormGroupModal(AddressFormGroupModalComponent, addressFG, nameFC).pipe(take(1)).subscribe(() => {
      const newContactAddress: IContactAddress = {
        address: addressFG.getRawValue(),
        name: nameFC.getRawValue()
      };
      control.setValue(newContactAddress);
      control.markAsDirty();
    });
  }

  getSmth(obj: any) {
    return this.getObjectValues(obj).join(', ');
  }
}
