import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IAddOrgMemberData } from 'src/app/shared/models/organization/members/add-org-member-data.model';
import { IRemoveOrgMemberData } from 'src/app/shared/models/organization/members/remove-org-member-data.model';
import { ISetMissingDataData } from 'src/app/shared/models/organization/set-missing-data-data.model';
import { IUpdateOrgMemberData } from 'src/app/shared/models/organization/members/update-org-member-data.model';
import { IUser } from 'src/app/shared/models/user/user.model';
import { CallableNames, DbService } from '../../db.service';
import { IInviteUnregisteredMemberData } from 'src/app/shared/models/organization/members/invite-unregistered-member-data.model';
import { IOrganization, IOrganizationCustomMemberFormSettings } from 'src/app/shared/models/organization/organization.model';
import { IAddress } from 'src/app/shared/modals/address-form-group-modal/address-form-group-modal.component';

export interface IOrganizationInfoUpdateData {
  orgId: number;

  legalName: string;
  email: string;
  customCssUrl?: string;

  customShopItemNumberActive: boolean;
  customShopItemNumberTemplate: string | null;
};

export interface ICallablesOrganizationsGetServiceFeesData {
  month: number; // 0-11
  year: number;
}

export interface IOrganizationsCustomDomainsUpdateData {
  orgId: number;

  customerDomain: string | null;
}
export interface IOrganizationsCustomDomainsVerifyData {
  orgId: number;

  customDomainUuid: string;
}

interface ICallablesOrganizationsMembersGetCustomFormSettingsData {
  orgId: number;
}

export enum CompanyInfoCountryCode {
  CZ = 'CZ',
  SK = 'SK',
  HU = 'HU'
}

export interface IOrganizationsCheckValidIcoCallableData {
  ico: string;
  countryCode: CompanyInfoCountryCode;
}

export interface ICompanyInfoResult {
  ok: boolean;
  companyInfo?: {
    ico: string;
    name: string;
    address: IAddress;
    vatNumber: string | null;
    vatPayer: boolean;
  }
}

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  constructor(
    private dbService: DbService,
  ) { }

  
  public hasEventOffers(organizationId: number): Observable<boolean> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrganizationsHasEventOffers, data: { organizationId } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getOrgCustomMemberFormSettings(data: ICallablesOrganizationsMembersGetCustomFormSettingsData): Observable<IOrganizationCustomMemberFormSettings[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrganizationsMembersGetCustomFormSettings, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public verifyCustomDomain(data: IOrganizationsCustomDomainsVerifyData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrganizationsCustomDomainsVerify, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
  public updateCustomDomains(data: IOrganizationsCustomDomainsUpdateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrganizationsCustomDomainsUpdate, data });
    return this.dbService.handleObs(obs);
  }

  public getServiceFees(data: ICallablesOrganizationsGetServiceFeesData): Observable<string> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrganizationsGetServiceFees, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getForSysAdmin(): Observable<IOrganization[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrganizationsGetForSysAdmin });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getByIds(ids: number[]) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrganizationsGet, data: { ids } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getOrgById(id: number): Observable<IOrganization> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrganizationsGet, data: { id } });
    return this.dbService.handleObs(obs, { succSilent: true }).pipe(
      map(x => {
        return x[0] ? x[0] : null;
      })
    );
  }

  public setMissingData(data: ISetMissingDataData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrganizationsSetMissingData, data });
    return this.dbService.handleObs(obs);
  }

  public updateInfo(data: IOrganizationInfoUpdateData): Observable<number> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrganizationsUpdateInfo, data });
    return this.dbService.handleObs(obs);
  }

  public addOrgMember( data: IAddOrgMemberData): Observable<IUser> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrganizationsMembersAdd, data });
    return this.dbService.handleObs(obs);
  }

  public updateOrgMember(data: IUpdateOrgMemberData): Observable<IUser | null> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrganizationsMembersUpdate, data });
    return this.dbService.handleObs(obs);
  }

  public removeOrgMember(data: IRemoveOrgMemberData): Observable<null> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrganizationsMembersRemove, data });
    return this.dbService.handleObs(obs);
  }

  public inviteUnregistered(data: IInviteUnregisteredMemberData): Observable<null> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrganizationsMembersInviteUnregistered, data });
    return this.dbService.handleObs(obs);
  }

  public createOrganization(): Observable<IOrganization> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrganizationsCreate });
    return this.dbService.handleObs(obs);
  }

  public getCompanyInfo(data: IOrganizationsCheckValidIcoCallableData): Observable<ICompanyInfoResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrganizationsGetCompanyInfo, data });
    return this.dbService.handleObs(obs, { allSilent: true });
  }

}
