import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Country_CZ } from '../../enums/countries/countries.enum';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

interface ICountry {
  name: string;
  countryCode: string;
}

@Component({
  selector: 'app-country-dropdown-input',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, TranslateModule,
    DropdownModule,
  ],
  templateUrl: './country-dropdown-input.component.html',
  styleUrls: ['./country-dropdown-input.component.scss']
})
export class CountryDropdownInputComponent implements OnInit, OnDestroy {

  @Input({ required: true }) countryFC!: FormControl<string>;
  @Input({ required: true }) countryCodeFC!: FormControl<string>;
  @Input() floatLabel: boolean = true;

  countries: ICountry[] = [];

  subs: Subscription[] = [];

  constructor(
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.createCountryOptions();
    this.subs.push(
      this.translate.onLangChange.subscribe(() => this.createCountryOptions())
    );

    // TMP, only for testing
    if (!this.countryCodeFC.value) this.countryCodeFC.setValue('CZ');
    this.setCountry(this.countryCodeFC.value);

    // zpetna kompatibilita - nekde tento dropdown nebyl a psalo se to primo - nekdo napsal jinak velka pismena
    // a v tom pripade se value v dropdownu nezobrazovala
    if (this.countryFC?.value?.toLocaleLowerCase() === Country_CZ.CZ.toLocaleLowerCase()) this.countryFC?.setValue(Country_CZ.CZ);
  }

  private createCountryOptions() {
    this.countries = Object.entries(Country_CZ).map(([countryCode, _]) => ({
      name: this.translate.instant(`country.${countryCode.toLowerCase()}`),
      countryCode: countryCode
    }));
  }

  onCountryChange(event: { originalEvent: Event; value: string; }) {
    this.setCountry(event.value);
  }

  setCountry(countryCode: string) {
    this.countryFC.setValue(this.translate.instant(`country.${countryCode.toLowerCase()}`));
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
