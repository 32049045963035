import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-hidden-input-wrap',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule
  ],
  templateUrl: './hidden-input-wrap.component.html',
  styleUrls: ['./hidden-input-wrap.component.scss']
})
export class HiddenInputWrapComponent implements OnInit {

  @Input() label: string = '';
  @Input() editable: boolean = false;
  @Input() FC: FormControl<any> | FormGroup<any> | null = null;

  @HostListener('click', ['$event'])
  click(e: Event) {
    this.onClick.next();
    // try to find input in host children
    const input = (e.target as HTMLElement).querySelector('input');
    if (input) {
      input.focus();
    }
  }

  @Output() onClick = new EventEmitter<void>;

  ngOnInit(): void {
    if (!this.FC) {
      throw new Error('FC is required');
    }

  }

  onDelete(e: Event) {
    e.stopPropagation();
    if (this.FC?.disabled) return;

    if ('controls' in this.FC!) {
      Object.values(this.FC!.controls).forEach((control) => {
        control.setValue(undefined);
        control.markAsDirty();
      });
    } else {
      this.FC?.setValue(undefined);
      this.FC?.markAsDirty();
    }
  }
}
